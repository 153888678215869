import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Social media marketing</h3>

                                <p>If you are getting started with internet marketing then Facebook marketing is probably the most suitable place for you. with more than 2.27 million users across the globe, Facebook is undoubtedly one of the largest platforms for digital marketing. Brands n behind understood the importance of Facebook marketing advertising because of the virtue of foresightedness. 
                                </p>
                                <Row>
                                    <Col md={6}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/social.jpg")} />
                                    </Col>
                                 
                                </Row>
                                <p>Thus, we have become the best Facebook marketing advertising agency in madurai. However, there is no bar for using Facebook we have targeted the audience of all the age groups including kids, adults and old age individuals. According to several surveys, Facebook has always remained at the top of the list of social platforms used by a large number of masses. An average amount of users globally uses Facebook for an hour every day.
                                </p>
                             
                          
                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;