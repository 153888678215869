import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Guerrilla Marketing</h3>
                                <p>Guerrilla marketing is an advertising approach that borrows the concept of “guerrilla”
                                    warfare, or the element of surprise, to communicate with target audiences. This form of
                                    marketing relies on unconventional and inventive displays to elicit wonder or shock and can be
                                    especially effective for driving publicity.</p>
                                    <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/guerilla.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/guerrilla-marketing.png")} />
                                            </Col>
                                    </Row>

                                <p>Guerrilla marketing campaigns are memorable and unconventional by nature and show the
                                    ability to leave a lasting impression on consumers. With a successful campaign, buyers are left
                                    amazed, impressed, and wanting to learn more about the product and/or service. This
                                    marketing strategy helps with brand recall.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;