import React from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./aboutus.css";
import { MdLocationOn } from 'react-icons/md';
import { FiPhoneCall } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { BiRightArrowAlt } from 'react-icons/bi';
import Iframe from 'react-iframe'

import { NavLink } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 


function Aboutus() {

    const settings1 = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,

        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },

            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const logos = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,

        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },

            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (

        <div className='aboutus'>

            <div className='aboutbann'>
                <Container>
                    <h1 className='abouthead'>
                        About Us
                    </h1>
                </Container>
            </div>

            <div className='aboutuspage'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div>
                                <h5 className='abouth5'> ABOUT US</h5>
                                <h1 className='abouth1'>
                                    We deal With The Aspects of Consultancy Services
                                </h1>

                                <p className='aboutpara'>
                                   IGNITE Branding Consultancy was founded in 2014 with a mission to provide branding
                                    and marketing analysis, and business consultancy services. We take pride in our
                                    knowledgeable team of professionals who prioritize complete client satisfaction
                                    and ethical business policies. We offer reliable services and stay in touch with our
                                    customers to provide transparent dealings and customized solutions. At IGNITE, we listen,
                                    understand, and provide solutions, ensuring a great experience with happy clients.
                                    Our Founder and Chairman, AMS Vairam, is a multifaceted professional with a strong
                                    organizational skillset and deep insight into the affairs of the profession.
                                    He has contributed to the cause of the profession for the past 9 years with his dedication,
                                    spectacular management skills, and service.
                                </p>


                            </div>
                        </Col>
                        <Col md={6}>
                            <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/abtus.png")} />

                        </Col>
                    </Row>
                </Container>
            </div>

        
            <div className='portfolio'>
                <Container fluid={true}>
                    <h3>TESTIMONIAL</h3>
                    <div className='adser'>
                        <Slider {...settings1}>
                            <div className='deer'>
                                <div className='card'>
                                    <p>Our association with ignitebranding consultancy had been a fruitful one. They took the project seriously and helped us launch the brand timely. I loved most about their service is that they deliver as promised.</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Praveen Paul</h6>
                                            <p style={{ fontSize: "14px" }}> PraWin Oil </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='deer'>
                                <div className='card'>
                                    <p>We worked with ignitebranding consultancy for almost 2 years and during the tenure the ignitebranding team has delivered their best. They have a strong communication channel where they keep you in loop for every single update. </p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Siva Sankar </h6>
                                            <p style={{ fontSize: "14px" }}>Food Cyclist </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='deer'>
                                <div className='card'>

                                    <p>With smart strategies and robust digital marketing approaches, our campaigns have got the right direction and ignitebranding had done the trick for us. We are now receiving good responses from potential customers and their keyword targeting strategy.</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Anil kumar R </h6>
                                            <p style={{ fontSize: "14px" }}> Veeru profiles  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='deer'>
                                <div className='card'>

                                    <p>
                                    Its a useful consulting session with Mr. SteynVairam. Normally when it comes to consulting, people will say just whatever they know or hear. But he is great that he can analyse  whats our  business need in need of actually. I would definitely refer him.</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Gowsalya Nallathambi</h6>
                                            <p style={{ fontSize: "14px" }}> Founder, Aura Kids International Preschool  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='deer'>
                                <div className='card'>
                                    <p>Our association with ignitebranding consultancy had been a fruitful one. They took the project seriously and helped us launch the brand timely. I loved most about their service is that they deliver as promised </p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Santhosh </h6>
                                            <p style={{ fontSize: "14px" }}> SIIM </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='deer'>
                                <div className='card'>
                                    <p>We worked with ignitebranding consultancy for almost 2 years and during the tenure the ignitebranding team has delivered their best. They have a strong communication channel where they keep you in loop for every single update. </p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Nagaraj </h6>
                                            <p style={{ fontSize: "14px" }}>Nagu Bags </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='deer'>
                                <div className='card'>

                                    <p>With smart strategies and robust digital marketing approaches, our campaigns have got the right direction and ignitebranding had done the trick for us. We are now receiving good responses from potential customers and their keyword targeting strategy.</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Anil kumar R </h6>
                                            <p style={{ fontSize: "14px" }}> Veeru profiles  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='deer'>
                                <div className='card'>

                                    <p>
                                    Its a useful consulting session with Mr. SteynVairam. Normally when it comes to consulting, people will say just whatever they know or hear. But he is great that he can analyse  whats our  business need in need of actually. I would definitely refer him.</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Gowsalya Nallathambi</h6>
                                            <p style={{ fontSize: "14px" }}> Founder, Aura Kids International Preschool  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Slider>
                    </div>
                </Container>

            </div>

   
            <div className='happyclient'>
                <Container>
                    <h3>HAPPY CLIENTS</h3>
                    <div className='adser'>
                        <Slider {...logos}>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client1.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client2.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client3.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client4.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client1.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client2.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client3.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client4.png")} />
                            </div>



                        </Slider>
                    </div>
                </Container>
            </div>






        </div>






    )
}
export default Aboutus;