import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './ad-sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                      
                        <Col md={8}>
                            <div>
                                <h3>Ad Film Making Advertisement</h3>

                                <p>Ad films create a bond between the products and the customer. Advertising films makes
                                    money by introducing your product in the market. The innovative techniques and the creative ad
                                    films work as a communication factor for your product which is valuable.
                                </p>
                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/AdFilm.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/Ad-Film2.png")} />
                                            </Col>
                                    </Row>
                                <p>An advertising film is a short, very concise film that is usually ten to a maximum of 60 seconds
                                    long. The aim is to draw attention to a product, a brand or a service and to promote its sales.

                                </p>

                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;