import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Digital Marketing</h3>
                                <p>Digital marketing, also called online marketing, is the promotion of brands to connect with
                                    potential customers using the internet and other forms of digital communication. This includes
                                    not only email, social media, and web-based advertising, but also text and multimedia
                                    messages as a marketing channel.</p>

                                    <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/digitalmar.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/digitalmar1.jpg.png")} />
                                            </Col>
                                    </Row>

                                <ul>
                                    <li>Global Reach. Traditional marketing is restricted by geography and creating an international
                                        marketing campaign can be hard, expensive, as well as labor-intensive.</li>
                                        <li>Local Reach.</li>
                                        <li>Lower Cost.</li>
                                        <li>Easy to Learn.</li>                                       
                                        <li>Effective Targeting.</li>
                                        <li>Multiple Strategies.</li>
                                        <li>Multiple Content Types.</li>
                                        <li>Increased Engagement.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;