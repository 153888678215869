import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Financial Budget Planning</h3>
                                <p>An advertising budget is an amount set aside by a company planned for the promotion of its
                                    goods and services. Promotional activities include conducting a market survey, getting
                                    advertisement creatives made and printed, promotion by way of print media, digital media, and
                                    social media, running ad campaigns, etc</p>
                                    <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/budjet.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/business/butjet.png")} />
                                            </Col>
                                    </Row>
                                <p>An advertising budget is important for a successful marketing plan. Your ad budget is the
                                    amount you plan to spend on paid promotion of your brand and/or products over a set time
                                    period, such as a year or a quarter.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;