import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./sidebar.css";

import AOS from "aos";
import "aos/dist/aos.css";

import { NavLink } from "react-router-dom"; 

import { BsDot } from 'react-icons/bs'; 

import { MdKeyboardArrowRight } from 'react-icons/md';

function Sidebar() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='sidebars'>
              
<div className="profile__body">
  
            <div className="list-group p-3">
            {/* <h5>Business Consultancy Services</h5> */}
                    <NavLink  to="/business-consultancy-services" className="list-group-item list-group-item-action" activeClassName="active"> <MdKeyboardArrowRight/> <span> Guerrilla marketing</span></NavLink>
                    <NavLink to="/casestudy" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Case Studies </span> </NavLink>
                    <NavLink to="/branding" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Branding </span> </NavLink>
                    <NavLink to="/financial-budget-planning" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Financial Budget Planning </span> </NavLink>
                    <NavLink to="/business-portfolio" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Business Portfolio </span> </NavLink>
                    <NavLink to="/video-marketing" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Video Marketing </span> </NavLink>
                    <NavLink to="/marketing-startegies" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Marketing Strategies </span> </NavLink>
                    <NavLink to="/market-penetration" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Market Penetration</span> </NavLink>
                    <NavLink to="/field-study" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Field Study / Market Survey</span> </NavLink>
                    <NavLink to="/digital-marketing" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Digital Marketing </span> </NavLink>
                    <NavLink to="/staff-traning" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight/> <span> Staff Training</span> </NavLink>
            </div>
        </div>

            </div>


        </>
    )
}

export default Sidebar;