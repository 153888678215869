import React from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Link} from "react-router-dom"
import "./footer.css";

import { NavLink } from 'react-router-dom';
import { MdLocationOn } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { TbDeviceLandlinePhone } from 'react-icons/tb';

import { BsFacebook, BsGoogle } from 'react-icons/bs';
import { BsTwitter } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { BsYoutube } from 'react-icons/bs';
import { GrInstagram } from 'react-icons/gr';

function Footer() {
    return (
        <div className='footer'>
            <div className='footerimg'>
                <Container>
                    <div>
                        <Row>
                            <Col md={3}>
                                <div>
                                    <div class="image-wrapper shine">
                                        <a href="/"><img src={require("../../assets/ibc2.png")} /></a>
                                    </div>
                                    <p className='abo'>IGNITE Branding Consultancy was founded in 2014 with a mission to provide branding and marketing analysis, and business consultancy services. </p>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div>
                                    <h4>Services</h4>
                                    <ul className='servicess'>
                                        <li><Link to="/business-consultancy-services" > Business consultancy services </Link>
                                            </li>
                                         <li><Link to="/print-advertisement" > Advertisement services </Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div>
                                    <h4>Company</h4>
                                    <ul className='servicess'>
                                        <li> <Link to="/aboutus" >About Us</Link></li>
                                        <li > <Link to="/contactus" >Contact Us</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='address'>
                                    <h4>Address</h4>
                                    <div style={{ display:"flex" }}>
                                    <span><MdLocationOn /></span>
                                    <p style={{ marginLeft:"4px" }}>115, Coronation Street, Arasaradi, Madurai- 625 016</p>
                                    </div>
                                    {/* <p><span><MdLocationOn /></span> 115, Coronation Street, Arasaradi, Madurai- 625 016</p> */}
                                    <p> <a href="tel:+91 96 55 12 6 222" style={{ color:"white", textDecoration:"none" }}><BsFillTelephoneFill style={{ fontSize:"18px" }}/> 96551 26222</a></p>
                                    <p> <a href="tel:+91 96 55 12 6 222" style={{ color:"white", textDecoration:"none" }}><TbDeviceLandlinePhone style={{ fontSize:"18px" }}/> 0452 - 495 00 11</a></p>
                                </div>
                                {/* <div className='socialmedia'>
                                    <BsFacebook className='icon' /> <BsTwitter className='icon' /> <BsLinkedin className='icon' /> <BsYoutube className='icon' />
                                </div> */}
                                <div className='member'>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target='_blank'>
                                        <BsFacebook className='icon icon1'/></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target='_blank'><BsTwitter className='icon icon2' /></a>
                                    </li>
                                    <li>
                                        <a href="https://in.linkedin.com/" target='_blank'><GrInstagram className='icon icon5' /></a></li>
                                    <li>
                                        <a href="https://in.linkedin.com/" target='_blank'><BsLinkedin className='icon icon3' /></a></li>
                                       
                                    {/* <li>
                                        <a href="https://support.google.com/" target='_blank'><BsGoogle className='icon icon4' /></a></li> */}
                                </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='copyright'>
                        <p>Copyright Ⓒ 2023 Ignite Branding Consultancy. All Right Reserved.</p>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Footer
