import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                      
                        <Col md={8}>
                            <div>
                                <h3>FM Radio Advertisement</h3>

                                <p>The madurai citizens are more involved in radio-subculture and are listeners of various English, Tamil and Hindi radio channels as per their accessibility. In madurai, popular radio channels like Radio City, Radio Mirchi, madurai Live, Aahaa FM, Rainbow, Prasar Bharathi, Vividh Bharati, Hello FM, and Red FM Suryan enjoy great listeners. There are so many interesting options do exist in the market for a radio ad campaign, and you can start your own ad campaign now at Brands N Behind, top FM radio ad agency in madurai!
                                </p>
                                <Row>
                                        <Col md={4}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/suryan.jpg")} />
                                        </Col>
                                        <Col md={4}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/fmm.jpg")} />
                                            </Col>
                                            <Col md={4}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/radio.jpg")} />
                                            </Col>
                                    </Row>
                                <p>Since madurai is the biggest economic, education and cultural center in South Asia, and the FM radio advertising in madurai seems to be the preferred option for almost all people. In madurai, the majority of people speak Tamil and the probability of listening to local music is much higher and hence advertising your business on FM channels broadcasting local music seems to be the best option. Additionally, for targeting the migrated population in madurai advertising in national FM channels seems effective. Nine popular FM channels are there in madurai which are well-known for broadcasting local information, music, and updates in various languages like Tamil, Hindi, and English. Also, the city has Vividh Bharati and All India Radio. Based on the number of listeners, each and every station has its own number audience list. FM advertising Ad rates are decided by the listener capacity and it will be varied for each and every station!
                                </p>

                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;