import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>TV Advertising </h3>

                                <p>Basically, commercials are broadcasted in TV slots which are nothing but a short duration of several seconds. Ratings of the slots are purely based on the popularity and reach of the program. Any commercial telecasted during this high rating slot will definitely earn the maximum number of viewers. Commercials on Television can be telecasted to viewers ranging from duration of a few seconds to minutes in order to display the information about the product. Television Advertisement in Madurai conveys detailed information about a product/service to the consumers in an efficient manner.
                                </p>
                             
                                <Row>
                                        <Col md={3}>
                                        <img style={{ marginTop: "10px", width:"50%" }} className='abtimgwidth' src={require("../../assets/tv1.jpg")} />
                                        </Col>
                                        <Col md={3}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/tv2.jpg")} />
                                        </Col>
                                        <Col md={3}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/tv3.jpg")} />
                                        </Col>
                                        <Col md={3}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/tv4.jpg")} />
                                        </Col>
                                    </Row>
                                <p>Additionally, on television, we have numerous channels each telecast shows in various fields like news, entertainment, sports, kids and knowledge-oriented and thus it helps business owners to showcase their commercial to the target audiences via a specialized program and channel. Even more, Television is becoming interactive nowadays via SMS service, contests, gifts, and prizes thereby trying to promote the brand identity and customer’s reach. Telemarketing and Teleshopping in television also plays a role as a direct medium for selling products.
                                </p>

                              
                                
                                <p>The nation’s most common leisure activity is watching TV. Television is having an impact on audiences of all demographics and age groups. Television advertising stands as the most powerful advertising medium with around 40 % of the direct impact rate. Brands N Behind is one of the top Television advertising agencies in Madurai and advertising via Brands N Behind allows you to reach regionally, locally and nationally easily. It offers the best rates and services for the effective building of the customer base and builds brand reputation by using efficient ad techniques.
                                </p>

                                 
                            
                              

                              


                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;