import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// home
import Home from "./Pages/Home/home";
import Contactus from "./Pages/Contactus/contactus";
import Aboutus from "./Pages/Aboutus/aboutus";

//Layouts
import Header from './Layouts/Header/header';
import Footer from './Layouts/Footer/footer';

// business
import Gurillaservices from './Pages/Business/gurillaservices';
import Casestudy from './Pages/Business/casestudy';
import Branding from './Pages/Business/branding';
import Businessportfolio from './Pages/Business/businessportfolio';
import Digital from './Pages/Business/digital';
import Field from './Pages/Business/fieldstudy';
import Financial from './Pages/Business/financial';
import Marketing from './Pages/Business/marketing';
import Penetration from './Pages/Business/marketpenetration';
import Staff from './Pages/Business/staff';
import Video from './Pages/Business/videomarketing';

// advertisement
import Print from './Pages/Advertisement/Print';
import Broadcast from './Pages/Advertisement/broadcast';
import Influencer from './Pages/Advertisement/influencer';
import Adflim from './Pages/Advertisement/adflim';
import Celebrity from './Pages/Advertisement/celebrity';
import Online from './Pages/Advertisement/online';
import Outdoor from './Pages/Advertisement/outdoor';
import Direct from './Pages/Advertisement/directmail';
import Point from './Pages/Advertisement/pointofsale';
import Product from './Pages/Advertisement/product';
import Sponsorship from './Pages/Advertisement/sponsorship';


// adagency
import Newspaper from './Pages/Adagencysidebar/newspaper';
import Fmradio from './Pages/Adagencysidebar/fmradio';
import Tv from './Pages/Adagencysidebar/tv';
import Autobrand from './Pages/Adagencysidebar/autobrand';
import Noparking from './Pages/Adagencysidebar/noparking';
import Buspack from './Pages/Adagencysidebar/buspack';
import Mobilevan from './Pages/Adagencysidebar/mobilevan';
import Shopping from './Pages/Adagencysidebar/shopping';
import Offset from './Pages/Adagencysidebar/offset';
import Airport from './Pages/Adagencysidebar/airport';
import Busshelter from './Pages/Adagencysidebar/busshelter';
import Metro from './Pages/Adagencysidebar/metro';
import Digital1 from './Pages/Adagencysidebar/digital';
import Facebook from './Pages/Adagencysidebar/facebook';
import Newspaperinsert from './Pages/Adagencysidebar/newspaperinsert';


import Adagencies from "./Pages/Adagencies/adagencies";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/contactus" element={<Contactus />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/adagencies" element={<Adagencies />} />

          {/* Business Services */}
          <Route path="/business-consultancy-services" element={<Gurillaservices />} />
          <Route path="/casestudy" element={<Casestudy />} />
          <Route path="/branding" element={<Branding />} />
          <Route path="/business-portfolio" element={<Businessportfolio />} />
          <Route path="/digital-marketing" element={<Digital />} />
          <Route path="/field-study" element={<Field />} />
          <Route path="/financial-budget-planning" element={<Financial />} />
          <Route path="/marketing-startegies" element={<Marketing />} />
          <Route path="/market-penetration" element={<Penetration />} />
          <Route path="/staff-traning" element={<Staff />} />
          <Route path="/video-marketing" element={<Video />} />

          {/* advertisement */}
          <Route path="/print-advertisement" element={<Print />} />
          <Route path="/broadcast-advertisement" element={<Broadcast />} />
          <Route path="/influencer-advertisement" element={<Influencer />} />
          <Route path="/adflimmaking-advertisement" element={<Adflim />} />
          <Route path="/celebrity-endrosement" element={<Celebrity />} />
          <Route path="/online-advertisement" element={<Online />} />
          <Route path="/outdoor-advertisement" element={<Outdoor />} />
          <Route path="/directmail-advertisement" element={<Direct />} />
          <Route path="/pointofsale-advertisement" element={<Point />} />
          <Route path="/product-placement-advertisement" element={<Product />} />
          <Route path="/sposorship-advertisement" element={<Sponsorship />} />


          {/* Adagency */}
          <Route path="/newspaper-advertisement" element={<Newspaper />} />
          <Route path="/fmradio-advertisement" element={<Fmradio />} />
          <Route path="/tv-advertisement" element={<Tv />} />
          <Route path="/autobranding-advertisement" element={<Autobrand />} />
          <Route path="/noparking-endrosement" element={<Noparking />} />
          <Route path="/buspackpanel-advertisement" element={<Buspack />} />
          <Route path="/mobilevan-advertisement" element={<Mobilevan />} />
          <Route path="/shoppingmalls-advertisement" element={<Shopping />} />
          <Route path="/offsetprinting-advertisement" element={<Offset />} />
          <Route path="/airport-advertisement" element={<Airport />} />
          <Route path="/busshelter-advertisement" element={<Busshelter />} />
          <Route path="/metrotrain-advertisement" element={<Metro />} />
          <Route path="/digitalmarketing-advertisement" element={<Digital1 />} />
          <Route path="/facebook-advertisement" element={<Facebook />} />
          <Route path="/newspaperinsert-advertisement" element={<Newspaperinsert />} />

        </Routes>

        <Footer />
      </BrowserRouter>


    </>
  )
}

export default App
