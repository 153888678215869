import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Marketing Strategies</h3>
                                <p>A marketing strategy refers to a business's overall game plan for reaching prospective
                                    consumers and turning them into customers of their products or services. A marketing strategy
                                    contains the company's value proposition, key brand messaging, data on target customer
                                    demographics, and other high-level elements.</p>

                                <p>An effective market strategy statement should provide a clear description of business goals and
                                    actions needed to complete these goals. Detailed instructions help prevent misinterpretation by
                                    team members.
                                </p>
                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/marstrategy.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/marstrategy1.jpg.png")} />
                                            </Col>
                                    </Row>
                                <p>Strategic aims include large goals you want to reach through your strategies. These can include
                                    things such as gaining a majority of market share, reaching a productivity rate of 90 percent of
                                    capacity or owning your facilities instead of renting them.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;