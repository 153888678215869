import React from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Link } from 'react-bootstrap';
import "./header.css";

import { MdKeyboardArrowDown } from 'react-icons/md';
import { BiPhoneOutgoing } from 'react-icons/bi';
import { NavLink } from 'react-router-dom'; 


function Header() {
    return (
        <div className='header'>
            {/* <Container fluid={true}>
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <div class="container-fluid">
                        <a href='/'> <img className='imgwidth' src={require("../../assets/IBCLogo.png")} /></a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="#">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#">About us</a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><a class="dropdown-item" href="#">Business consultancy services</a></li>
                                        <li><a class="dropdown-item" href="#">Advertisement services </a></li>

                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " href=''>Portfolios</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " href=''>Contact us</a>
                                </li>
                            </ul>
                            <form class="d-flex">
                                <a href='/'> <img className='imgwidth' src={require("../../assets/mobilenumber.png")} /></a>
                            </form>
                        </div>
                    </div>
                </nav>
            </Container> */}


            <Navbar bg="light" expand="lg">
                <Container fluid={true}>
                    <Navbar.Brand href="/"><img className='imgwidth' src={require("../../assets/ibc2.png")} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavLink to="/" className="sss" activeClassName="active">HOME</NavLink>
                            <NavLink to="/aboutus" className="sss">ABOUT</NavLink>
                            <NavDropdown title="BUSINESS CONSULTANT" className="sss" id="basic-nav-dropdown">
                                {/* <NavDropdown.Item href="/business-consultancy-services">Business consultancy services</NavDropdown.Item>
                                <NavDropdown.Item href="/print-advertisement">Advertisement services</NavDropdown.Item> */}
                                <NavDropdown.Item><NavLink to="/business-consultancy-services"> Guerrilla marketing</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/casestudy">  Case Studies</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/branding"> Branding</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/financial-budget-planning"> Financial Budget Planning</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/business-portfolio"> Business Portfolio</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/video-marketing"> Video Marketing</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/marketing-startegies"> Marketing Strategies</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/market-penetration"> Market Penetration</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="//field-study"> Field Study / Market Survey</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/digital-marketing"> Digital Marketing</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/staff-traning"> Staff Training</NavLink></NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title="BRANDING & PROMOTIONS" className="sss" id="basic-nav-dropdown">
                                {/* <NavDropdown.Item href="/business-consultancy-services">Business consultancy services</NavDropdown.Item>
                                <NavDropdown.Item href="/print-advertisement">Advertisement services</NavDropdown.Item> */}
                                <NavDropdown.Item><NavLink to="/print-advertisement"> Print Advertisement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/broadcast-advertisement">  Broadcast Advertisement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/influencer-advertisement"> Influencer Advertisement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/adflimmaking-advertisement"> Ad Film Making Advertisement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/celebrity-endrosement"> Celebrity Endorsement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/online-advertisement"> Online Advertisement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/outdoor-advertisement"> OutDoor Advertisement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/directmail-advertisement"> Direct Mail Advertisement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/pointofsale-advertisement"> Point of Sale Advertisement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/product-placement-advertisement">Product Placement Advertisement</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink to="/sposorship-advertisement"> Sponsorship Advertisement</NavLink></NavDropdown.Item>
                            </NavDropdown>


                            {/* <NavLink to="/print-advertisement" className="sss">BRANDING & PROMOTIONS</NavLink> */}

                            <NavLink to="/adagencies" className="sss">ADVERTISEMENT </NavLink>
                            <NavLink to="/contactus" className="sss contactheader">CONTACT</NavLink>

                        </Nav>
                        <form class="d-flex">
                            <a href="tel:+91 96 55 12 6 222" className='tela'> 
                            <p style={{ color:"black" }}>Have Any Question?</p>
                            <p className='nos' style={{ fontWeight:"600", marginTop:"2px" }}> <BiPhoneOutgoing/> <span>96 55 12 6 222</span></p>
                             {/* <img className='imgwidth' src={require("../../assets/mobilenumber.png")} /> */}
                            </a>
                        </form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


        </div>
    )
}

export default Header
