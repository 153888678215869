import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './ad-sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                     
                        <Col md={8}>
                            <div>
                                <h3>Direct Mail Advertisement</h3>
                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/direct-mail.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/direct-mail1.png")} />
                                            </Col>
                                    </Row>
                                <p>Direct mail is a form of advertising in which physical mail pieces (letters, postcards,
                                    brochures, catalogs, etc.) are sent directly to potential customers. It's a form of outbound
                                    marketing that allows businesses to reach a large audience and personalize their messages to
                                    target specific demographics.
                                </p>

                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;