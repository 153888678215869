import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './ad-sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                      
                        <Col md={8}>
                            <div>
                                <h3>OutDoor Advertisement</h3>

                                <p>Outdoor advertising is any kind of advertisement that is displayed outside, usually in public
                                    areas such as roadside billboards, bus stops, train stations, and other public spaces. It can also
                                    include banners and signs hung in public areas, such as on buildings, parks, and beaches.
                                </p>

                                <p>Outdoor advertising includes various types of promotional displays, from roadside billboards to
                                    transit posters and arena placement, all geared towards communicating a message to the public
                                    on a large scale. Benefits: Strategically placed outdoor advertising guarantees substantial
                                    exposure for very little investment.</p>
                                    <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/outdoor.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/outdoor1.png")} />
                                            </Col>
                                    </Row>
                                <ul>
                                    <li>Billboards. Bulletins or Billboards, as they're commonly called, are large and impactful media types. </li>
                                    <li> Digital Billboards. </li>
                                    <li>Wallscapes. </li>
                                    <li>Murals</li>
                                    <li>Urban Street Furniture. </li>
                                    <li>Bus Shelters. </li>
                                    <li>Airports. </li>
                                    <li>Busses.</li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;