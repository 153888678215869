import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Mobile Van Advertising</h3>

                                <p>Just imagine, a mobile van with an attractive billboard moving across the city. It will catch the attention of every eye moving on the road. Consumers prefer viewing the brand name initially followed by the key contents. Hence, preferring mobile van advertising in madurai will fetch all the business owners a huge revenue return with improvised brand identity in the market.
                                </p>
                                <Row>
                                    <Col md={6}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/vanlabel.jpg")} />
                                    </Col>
                                 
                                </Row>
                                <p>Being a brand owner, you can thus create brand awareness among the target audience via mobile van advertisements in madurai. You can improve sales volume in case of launching a new product in the market via mobile van advertising in madurai. Unlike other transportation options like car or auto, mobile vans have a required amount of space behind it. Additionally, interesting benefit in mobile van advertisements is that geographical targeting can be achieved and hence you can improve your brand identity in certain regions or locality as per your preferences with catchy key contents and colorful designs.
                                </p>
                             
                                <p>High visibilities, larger impact, better impressions, targeted outcomes are the key features of mobile van advertising in madurai. Generally, mobile van advertising is performed via fixing a billboard with attractive advertising contents on the truck which navigates throughout the city and can also be parked at the target localities for grasping the attention of the consumers to the maximum reach. Because of illumination, mobility, and capability to navigate different locations according to the convenience, it’s regarded as one of the perfect media for advertising your brand or service to the consumers.
                                </p>

                                <p>madurai is one of the busiest cities in South India and people here usually navigate from one place to another for various needs. Hence branding your business via mobile van will grasp their attention and may turn them into potential buyers. For branding your business via mobile van advertising in madurai, hiring a low-cost mobile van advertising agency in madurai will make the advertising campaign easier. Brands N Behind, leading mobile van advertising agency in madurai and they are capable of branding your business with innovative designs and key contents with the goal of earning huge revenue returns and brand recognition in the market. Of course, quick recognition is the key requirement in any business for earning a profit! You can achieve this by joining hands with Brands N Behind in madurai.
                                </p>
                             
                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;