import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Newspaper Inserts Advertising</h3>

                                <p>Newspaper insertion is also known by various names like newspaper pamphlet insertion, pamphlet distribution, and newspaper leaflet inserts and so on. Newspaper inserts are actually a pamphlet or a loose leaflet that does come with the newspaper usually. It can be done at apartment, locality or colony level by the newspaper distributors or by the newspaper inserts advertising agency in Madurai. Brands N Behind, a leading newspaper inserts advertising agency in Madurai aims at promoting your brand among the target audience across the city.
                                </p>
                             

                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/newsadd1.jpg")} />
                                        </Col>
                                       
                                    </Row>

                                <p style={{ fontWeight: "600" }}>Why advertise your brand on newspaper inserts in Madurai?
                                </p>
                                
                            
                                <ul>
                                    <li>Newspaper pamphlet insertion in Madurai seems to be an effective approach to cover up a certain locality or colony for your brand promotion. Instead of wasting money on covering a huge area, you can focus on smaller population locality according to your target audience category via newspaper inserts in Madurai. </li>
                                    <li>Advertising via newspaper inserts will highlight your key advertising content in consumer’s minds to a great extent. Higher visibility and the larger impact can be achieved and thereby boosts the consumers to own your product or service. </li>
                                    <li>Unlike newspaper advertisements in Madurai, you need not look for a way to grasp the audience’s attention to your brand message. You can easily gain visibility via newspaper inserts. </li>
 
                                </ul>


                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;