import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Business Portfolio</h3>
                                <p>A business portfolio is a document that contains important information about an organisation,
                                    including details about what the organisation does, its goals, available assets and mission.
                                    Business leaders can use portfolios to organise information and make informed decisions.</p>

                                <p>Portfolio analysis is a quantitative method for selecting an optimal portfolio that can strike a
                                    balance between maximizing the return and minimizing the risk in various uncertain
                                    environments.
                                </p>
                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/port.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/port1.png")} />
                                            </Col>
                                    </Row>
                                <p>A business portfolio is a group of products, services, and business units that conform a given
                                    company and allows it to pursue its strategic goals. This portfolio can also be defined as the set
                                    of available assets that the company posses to develop its mission and reach its vision.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;