import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';

import AOS from "aos";
import "aos/dist/aos.css";

import { NavLink } from "react-router-dom";

import { BsDot } from 'react-icons/bs';

import { MdKeyboardArrowRight } from 'react-icons/md';

function Sidebar() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='sidebars'>

                <div className="profile__body">
                    <div className="list-group p-3">
                    <h5>Advertisement Services</h5>
                        <NavLink to="/print-advertisement" className="list-group-item list-group-item-action" activeClassName="active"> <MdKeyboardArrowRight /> <span> Print Advertisement</span></NavLink>
                        <NavLink to="/broadcast-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Broadcast Advertisement </span> </NavLink>
                        <NavLink to="/influencer-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Influencer Advertisement </span> </NavLink>
                        <NavLink to="/adflimmaking-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Ad Film Making Advertisement </span> </NavLink>
                        <NavLink to="/celebrity-endrosement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Celebrity Endorsement </span> </NavLink>
                        <NavLink to="/online-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Online Advertisement </span> </NavLink>
                        <NavLink to="/outdoor-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span>OutDoor Advertisement </span> </NavLink>
                        <NavLink to="/directmail-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Direct Mail Advertisement</span> </NavLink>
                        <NavLink to="/pointofsale-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Point of Sale Advertisement</span> </NavLink>
                        <NavLink to="/product-placement-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Product Placement Advertisement </span> </NavLink>
                        <NavLink to="/sposorship-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Sponsorship Advertisement</span> </NavLink>
                    </div>
                </div>

            </div>


        </>
    )
}

export default Sidebar;