import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Case Studies</h3>
                                <p>A business case study summarizes a real-life business issue faced by a company and looks
                                    at how it may affect society within a business context. A case study is a research tool and can
                                    form part of the research methods you will use in your final projects in our programs.</p>
                                    <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/casestudyfree.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/casestudy2.png")} />
                                            </Col>
                                    </Row>
                                <p>Case studies are valuable data sources for researchers in view of the complexity and diversity
                                    of educational settings and purposes. Case study research has an important role in putting
                                    theories into practice, thus developing the practice in the field of educational sciences.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;