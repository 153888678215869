import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Newspaper Advertising</h3>

                                <p>Almost all business owners thought of advertising their products or services via newspapers initially since it covers all categories of people in the world.
                                </p>
                             
                                <p>Madurai, Gateway of South India, is the largest economic, cultural and educational center of south India. A huge population in Madurai love to read newspapers and hence newspaper advertising in Madurai will fetch a huge revenue return for any business owner in Madurai.
                                </p>

                                <Row>
                                        <Col md={4}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/news1.png")} />
                                        </Col>
                                        <Col md={4}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/news2.png")} />
                                            </Col>
                                            <Col md={4}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/news3.png")} />
                                            </Col>
                                    </Row>

                                <p>Now, you may think about how to advertise your business with catchy content? Answer to your question is a Newspaper advertising agency in Madurai. Choosing the right newspaper advertising agency in Madurai will help you in the promotion of your business to a great extent!
                                </p>

                                <p style={{ fontWeight: "600" }}>Why you should advertise in Newspapers?
                                </p>
                                <p>We may not be familiar with the use of newspaper advertising. Why it’s beneficial to advertise in Newspapers? Let’s explore!</p>
                                <ul>
                                    <li style={{ fontWeight: "600" }}>Wide Coverage </li>
                                    <p>National, local and regional markets are covered by newspapers. Additionally, they can serve people of all income levels and age groups both in urban as well as rural areas.</p>
                                    <li style={{ fontWeight: "600" }}>Faster Response  </li>
                                    <p>Current News happening in day-to-day life are conveyed via Newspapers. Everyday people used to read newspapers in order to know the development in various fields in the market. Newspaper advertising generally has a sense of urgency. You can get an immediate response from the people via newspaper advertising when compared to other modes of advertising.</p>
                                    <li style={{ fontWeight: "600" }}>Frequent and Regular  </li>
                                    <p>If you want to get the frequent and periodic response from the public, then the best option for your business is newspaper advertising. It reminds the public regularly to the consumers and keeps them updated regularly.</p>
                                    <li style={{ fontWeight: "600" }}>Flexibility</li>
                                    <p>Advertisements published in newspapers can be altered or modified overnight according to the requirement of the business owner. Hence, it’s highly flexible than other advertising modes. Retail owners can be benefited to the maximum extent via newspaper advertising since newly arrived products and its features can be listed easily in order to boost sales. Thus, the main aspect of Newspaper advertising is its flexibility.</p>
                                    <li style={{ fontWeight: "600" }}>Economical</li>
                                    <p>Advertising your business in newspapers will cost less and it will be economical for almost all business owners starting from a startup organization to a developed organization.</p>
                                </ul>


                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;