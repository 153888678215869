import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                      
                        <Col md={8}>
                            <div>
                                <h3>Auto Advertising</h3>

                                <p>Auto advertising is one of the most convenient ways of boosting sales. As the majority of the population travels in auto rather than in any other public transport in madurai. This advertising takes your ads to every nook and corner of the city people from diversified categories get to see your product and services advertisement. As a matter of fact, auto advertising can reach those places in the city too where the other modes of transportation do not reach. At brands n behind, we do multiple types of auto branding in madurai. 
                                </p>
                                <Row>
                                        <Col md={12}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/auto1.jpg")} />
                                        </Col>
                                        {/* <Col md={6}>
                                        <img style={{ marginTop: "10px", width:"100%"  }} className='abtimgwidth' src={require("../../assets/auto1.jpg")} />
                                      
                                            </Col> */}
                                    </Row>
                                <p>Thus, brands n behind is a stop shop for resolving all the auto advertising services company in madurai. We provide the below-mentioned services:
                                </p>

                                <ul>
                                    <li>Auto Sticker Advertisement in madurai </li>
                                    <li>Auto Full Hood Advertisement in madurai </li>
                                    <li>Auto Back Panel Advertisement in madurai </li>
                                    <li>Auto Meter Cover Advertisement in madurai</li>
                                   
                                </ul>

                             
                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;