import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './ad-sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                     
                        <Col md={8}>
                            <div>
                                <h3>Broadcast Advertisement</h3>

                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/brad1.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/broadcast.png")} />
                                            </Col>
                                    </Row>

                                <p>Generally speaking, Broadcast advertising is Radio, Television, and Internet advertising. The
                                    commercials Aired on Radio and Televisions are an essential part of Broadcast Advertising. The
                                    broadcast media like Radio and Television reaches a wider audience.
                                </p>


                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;