import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Train Advertising</h3>

                                <p>Quick recognition and better target audience reach are the key requirements for all business owners in the market! Metro train advertising in madurai will fetch these requirements to all the advertisers across the city. Both startups, as well as big business organizations, can be benefited via metro train advertisements in madurai. Metro ads are actually an end-to-end advertising option in which you can cover the target audience from the station concourse to the platform as well as on the train itself. You may have the opportunity of targeting highly receptive audiences in the metro trains since on average they may spend around 4 minutes waiting on the platform before starting their trip.
                                </p>
                                <Row>
                                    <Col md={6}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/metroo.jpg")} />
                                    </Col>
                                 
                                </Row>
                                <p>Many individuals across the city may think that both metro train advertising in madurai and railway advertising option as synonyms but the actual truth is that, they are not. The metro train advertisements have varied features when compared to railway advertisements and hence it’s different in terms of target audience, location, and approach. Nowadays, the network of metro trains is enlarging and hence preferring the metro train advertising option seems to be a perfect one.
                                </p>
                             
                                <p>For advertising via metro trains in madurai, you need to eye-catching advertising content and innovative designs and this can be done with the help of a metro train advertising agency in madurai. Brands N Behind, an experienced metro train advertising agency in madurai has many years of expertise in serving potential clients in the market. We assure all our clients with better brand recognition and massive target audiences reach by creating catchy colorful advertising designs and content. We are the leading metro train advertising agency in madurai and can offer you special offers and affordable advertising ad rates and costs unlike other advertising agencies in madurai. We keep you informed about all the outcomes of the advertisement via our team of professionals.
                                </p>
                             
                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;