import React, { useState, useEffect, } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./home.css";
import { BiRightArrowAlt } from 'react-icons/bi';


import { NavLink } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AOS from "aos";
import "aos/dist/aos.css";

function Home() {



    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {


        setWidth(window.innerWidth);

    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    // return (width <= 768);






    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    console.clear();
    let angle = 360 / 6;
    let finalAngle = -360;

    const circleContainer = document.querySelectorAll(".circle");



    circleContainer.forEach(circle => {


        finalAngle -= 60;

        circle.style.transform = `rotate(${angle}deg)`;
        circle.style.setProperty("--start-angle-of-rotation", `-${angle}deg`);
        circle.style.setProperty("--end-angle-of-rotation", `${finalAngle}deg`);


        angle += 60;

    });



    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,

        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },

            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const settings1 = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,

        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },

            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const logos = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,

        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },

            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className='home'>

            <div className='bannersection'>
                <Container fluid={true}>
                    <Row style={{ alignItems: "center" }}>



                        {width <= 768 ?
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ marginTop: "10px" }} className='homeimgwidth indianimg' src={require("../../assets/indian1.jpg")} />
                            </Col>
                            : null}

                        <Col md={6}>
                            <div data-aos="fade-up" data-aos-duration="3000" className='bannersection1 backgrnd'>
                                <h5 className='heading4'>
                                    ONE STOP SOLUTIONS
                                </h5>
                                <h1 className='heading1'>

                                    WE HELP<span className='heading7'> YOUR </span> <span className='heading6'>  BUSINESS</span> <span className='heading7'>GROW</span>
                                </h1>
                                <p className='ubuntu'></p>
                                <p className='fstpara'>
                                    At IBC, we understand the importance of reaching the right audience at the right time with the right message. That's why we offer a range of advertising and promotion services designed to help businesses of all sizes achieve their marketing goals.

                                </p>
                                {/* <p className='degree' style={{ marginBottom: "0px" }}>360 Degree Marketing</p>

                                <img src={require("../../assets/m4.png")} /> */}
                                {/* <div>
                                    <a href='/contactus' className='homeatag'><button type="button" class="btn btn-primary btn-lg">Contact Us <BiRightArrowAlt className='marbtm4' /></button></a>
                                </div> */}
                            </div>
                        </Col>

                        {width >= 768 ?
                            <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                                <img style={{ marginTop: "10px" }} className='homeimgwidth indianimg' src={require("../../assets/indian1.jpg")} />
                            </Col>
                            : null}
                    </Row>

                </Container>

                <div className='kiii' >

                    <Container>
                        {/* <h5>360° Degree Marketing Solutions</h5> */}
                        <div className='circless' data-aos="fade-up" data-aos-duration="3000">
                            <div class="circle-container">
                                <div><p>Creative Development</p></div>
                                <div><p>Media
                                    Planning &
                                    Buying</p></div>
                                <div><p>Digital
                                    Advertising</p></div>
                                <div><p>Social Media
                                    Marketing </p></div>
                                <div><p>Promotions
                                    & Activation</p></div>

                            </div>

                            <div className='centerpoint'>

                                <h5>360° <br></br> Marketing <br></br> Solutions</h5>
                            </div>

                        </div>
                    </Container>
                </div>

            </div>




            <div className='secbackimg'>
                <Container>
                    <Row>
                        <Col md={5} data-aos="zoom-in-down" data-aos-duration="3000">
                            <h3 className='busiheading'>
                                Business consultancy services
                            </h3>
                            <p className='clrwht'>Unlock your business's potential with our expert
                                consultancy services and take it to new heights.</p>
                            <div className='pooo'>
                                <a href='/business-consultancy-services' className='homeatag'><button type="button" class="btn btn-success btn-lg"><span>View All</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                            </div>
                        </Col>
                        <Col md={2}>
                        </Col>
                        <Col md={5} data-aos="zoom-in-up" data-aos-duration="3000">
                            <h3 className='busiheading trr'>
                                Advertisement services
                            </h3>
                            <p className='clrwht'>Maximize your reach and impact with our
                                innovative advertising solutions.</p>
                            <div>
                                <a href='/print-advertisement' className='homeatag'><button type="button" class="btn btn-warning btn-lg"><span>View All</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </div>


            <div className='advertisement'>
                <Container>
                    <h2>Advertisement Services</h2>

                    <div className='adser'>
                        <Slider {...settings}>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/advertising.png")} />
                                    {/* <Card.Img variant="top" src={require("../../assets/influ.webp")} /> */}
                                    <Card.Body>
                                        <Card.Title>Influencer Advertisement</Card.Title>
                                        <Card.Text>
                                            Influencer marketing is a form of marketing that enables businesses to collaborate with
                                            individuals who have a following for increased brand exposure.

                                        </Card.Text>
                                        <span class="top"></span>
                                        <span class="right"></span>
                                        <span class="bottom"></span>
                                        <span class="left"></span>


                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/celebrity (2).png")} />
                                    <Card.Body>
                                        <Card.Title>Celebrity Endrosement</Card.Title>
                                        <Card.Text>
                                            Celebrity branding is a form of advertising campaign or marketing strategy which uses a
                                            celebrity's fame or social status to promote a product..
                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/road-banner.png")} />
                                    <Card.Body>
                                        <Card.Title>Outdoor Advertisement</Card.Title>
                                        <Card.Text>
                                            Outdoor advertising is any kind of advertisement that is displayed outside, usually in public
                                            areas such as roadside billboards, bus stops, train stations, and other public spaces.
                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/film-reel.png")} />
                                    <Card.Body>
                                        <Card.Title>Ad Flim Making</Card.Title>
                                        <Card.Text>
                                            Ad films create a bond between the products and the customer. Advertising films makes
                                            money by introducing your product in the market.
                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/marketing.png")} />
                                    <Card.Body>
                                        <Card.Title>Digital Marketing</Card.Title>
                                        <Card.Text>
                                            Digital marketing, also called online marketing, is the promotion of brands to connect with
                                            potential customers using the internet and other forms of digital communication.
                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>

                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/sales.png")} />
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: "17px" }}>Point of sales Advertisement</Card.Title>
                                        <Card.Text>
                                            Point of sale marketing (or POS advertising) is, specifically, a form of in-store promotion or
                                            marketing campaign that takes place at the point a transaction occurs..

                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>
                        </Slider>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <a href='/print-advertisement' className='homeatag'><button type="button" class="btn btn-primary btn-lg">View All <BiRightArrowAlt className='marbtm4' /></button></a>
                    </div>
                </Container>
            </div>


            <div className='aboutus'>
                <Container>
                    <Row style={{ alignItems: "center" }}>
                        <Col md={6} data-aos="fade-up" data-aos-duration="3000">
                            <img className='homeimgwidth' src={require("../../assets/abtus.png")} />
                        </Col>
                        <Col md={6} data-aos="fade-down" data-aos-duration="3000">
                            <div className='paddtp'>
                                <h2 className='abthead'>
                                    ABOUT US
                                </h2>
                                <p className='abtpara'>
                                    IGNITE Branding Consultancy was founded in 2014 with a mission to provide branding
                                    and marketing analysis, and business consultancy services. We take pride in our
                                    knowledgeable team of professionals who prioritize complete client satisfaction
                                    and ethical business policies. We offer reliable services and stay in touch with our
                                    customers to provide transparent dealings and customized solutions. At IGNITE, we listen,
                                    understand, and provide solutions, ensuring a great experience with happy clients.
                                    Our Founder and Chairman, AMS Vairam, is a multifaceted professional with a strong
                                    organizational skillset and deep insight into the affairs of the profession.
                                    He has contributed to the cause of the profession for the past 9 years with his dedication,
                                    spectacular management skills, and service.
                                </p>
                                <a href='/aboutus' className='homeatag'><button type="button" class="btn btn-primary btn-lg">READ MORE  <BiRightArrowAlt className='marbtm4' /></button></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className='business'>
                <Container>
                    <h2>Business Consultancy Services</h2>

                    <div className='adser'>
                        <Slider {...settings}>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/content-marketing.png")} />
                                    {/* <Card.Img variant="top" src={require("../../assets/influ.webp")} /> */}
                                    <Card.Body>
                                        <Card.Title>Guerrilla marketing</Card.Title>
                                        <Card.Text>
                                            Guerrilla marketing is an advertising approach that borrows the concept of “guerrilla”
                                            warfare, or the element of surprise, to communicate with target audiences.

                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/analysis.png")} />
                                    <Card.Body>
                                        <Card.Title>Case Studies</Card.Title>
                                        <Card.Text>
                                            A business case study summarizes a real-life business issue faced by a company and looks
                                            at how it may affect society within a business context.
                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/budget.png")} />
                                    <Card.Body>
                                        <Card.Title>Financial Budget Planning</Card.Title>
                                        <Card.Text>
                                            An advertising budget is an amount set aside by a company planned for the promotion of its
                                            goods and services.
                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/plan.png")} />
                                    <Card.Body>
                                        <Card.Title>Marketing Strategies</Card.Title>
                                        <Card.Text>
                                            A marketing strategy refers to a business's overall game plan for reaching prospective
                                            consumers and turning them into customers of their products or services.
                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>
                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/brand-identity.png")} />
                                    <Card.Body>
                                        <Card.Title>Branding</Card.Title>
                                        <Card.Text>
                                            Branding is the process of creating a distinct identity for a business in the mind of your target
                                            audience and consumers.
                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>

                            <div className='deer'>
                                <Card>
                                    <Card.Img variant="top" src={require("../../assets/training.png")} />
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: "17px" }}>Staff Training</Card.Title>
                                        <Card.Text>
                                            We understand that investing in your staff's development is critical to the success of your
                                            business. That's why we work closely with you to ensure that our training programs..
                                        </Card.Text>

                                    </Card.Body>
                                    <span class="top"></span>
                                    <span class="right"></span>
                                    <span class="bottom"></span>
                                    <span class="left"></span>
                                </Card>
                            </div>
                        </Slider>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <a href='/business-consultancy-services' className='homeatag'><button type="button" class="btn btn-primary btn-lg">View All <BiRightArrowAlt className='marbtm4' /></button></a>
                    </div>
                </Container>
            </div>

            <div className='portfolio'>
                <Container fluid={true}>
                    <h3>TESTIMONIAL</h3>
                    <div className='adser'>
                        <Slider {...settings1}>
                            <div className='deer'>
                                <div className='card'>
                                    <p>Our association with ignitebranding consultancy had been a fruitful one. They took the project seriously and helped us launch the brand timely. I loved most about their service is that they deliver as promised.</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Praveen Paul </h6>
                                            <p style={{ fontSize: "14px" }}> PraWin Oil </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='deer'>
                                <div className='card'>
                                    <p>We worked with ignitebranding consultancy for almost 2 years and during the tenure the ignitebranding team has delivered their best. They have a strong communication channel where they keep you in loop for every single update. </p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Siva Sankar </h6>
                                            <p style={{ fontSize: "14px" }}>Food Cyclist </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='deer'>
                                <div className='card'>

                                    <p>With smart strategies and robust digital marketing approaches, our campaigns have got the right direction and ignitebranding had done the trick for us. We are now receiving good responses from potential customers.</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Anil kumar R </h6>
                                            <p style={{ fontSize: "14px" }}> Veeru profiles  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='deer'>
                                <div className='card'>

                                    <p>
                                        Its a useful consulting session with Mr. SteynVairam. Normally when it comes to consulting, people will say just whatever they know or hear. But he is great that he can analyse  whats our  business need in need.</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Gowsalya Nallathambi</h6>
                                            <p style={{ fontSize: "14px" }}> Founder and Director, Aura Kids International Preschool  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='deer'>
                                <div className='card'>
                                    <p>Our association with ignitebranding consultancy had been a fruitful one. They took the project seriously and helped us launch the brand timely. I loved most about their service is that they deliver as promised </p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Santhosh </h6>
                                            <p style={{ fontSize: "14px" }}> SIIM </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='deer'>
                                <div className='card'>
                                    <p>We worked with ignitebranding consultancy for almost 2 years and during the tenure the ignitebranding team has delivered their best. They have a strong communication channel where they keep you in loop for every single update. </p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Nagaraj </h6>
                                            <p style={{ fontSize: "14px" }}>Nagu Bags </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='deer'>
                                <div className='card'>

                                    <p>With smart strategies and robust digital marketing approaches, our campaigns have got the right direction and ignitebranding had done the trick for us. We are now receiving good responses from potential customers .</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Anil kumar R </h6>
                                            <p style={{ fontSize: "14px" }}> Veeru profiles  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='deer'>
                                <div className='card'>

                                    <p>
                                        Its a useful consulting session with Mr. SteynVairam. Normally when it comes to consulting, people will say just whatever they know or hear. But he is great that he can analyse  whats our  business need in need.</p>
                                    <div className='portfolioflex'>
                                        {/* <div>
                                            <img src={require("../../assets/ip1.png")} />
                                        </div> */}
                                        <div style={{ marginLeft: "10px" }}>
                                            <h6>Gowsalya Nallathambi</h6>
                                            <p style={{ fontSize: "14px" }}> Founder and Director, Aura Kids International Preschool  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Slider>
                    </div>
                </Container>

            </div>


            <div className='happyclient'>
                <Container>
                    <h3>HAPPY CLIENTS</h3>
                    <div className='adser'>
                        <Slider {...logos}>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client1.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client2.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client3.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client4.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client1.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client2.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client3.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/client4.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/aura-kids.png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/meenachi-hotel (3).jpg")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/images (2).png")} />
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img src={require("../../assets/processed-c7fd8151-812c-41ab-b91a-cc0c75d0c513_U2d2dDPg (1).jpeg")} />
                            </div>
                        </Slider>
                    </div>
                </Container>
            </div>


        </div>
    )
}

export default Home;
