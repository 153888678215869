import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Bus Shelter Advertising</h3>

                                <p>Bus shelters are nothing but the localities where the consumers across the city wait and they may come in contact with your advertisement featuring interesting facts about your brand. Choosing the perfect place for featuring your advertisement will help you in catching the huge of consumers. Presently, bus shelters are coming in neat looking spaces where you can display your brand advertisement effortlessly and can earn huge revenue return via creative advertisement contents. For developing creative content as well as innovative designs you need to hire an authorized <span style={{ fontWeight:"600"}}>bus shelter advertising agency in madurai</span>. Brands N Behind, a leading <span style={{ fontWeight:"600"}}>bus shelter advertising agency in madurai</span> and that has specialization in the bus shelter advertising in madurai for more number of years.
                                </p>
                                <Row>
                                    
                                    <Col md={12}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/busshell1.jpg")} />
                                    </Col>
                                 
                                </Row>
                                <p>Bus shelter advertising in madurai is actually a fantastic medium for both startups as well as big business companies to reach out to the target audience across the city. It’s one of the most used advertising options by the advertisers in order to improvise the brand identity and sales volume. Advertising via bus shelters in madurai is a flexible and adaptable mode of outdoor advertising and its ideal for both small as well as large campaigns. It offers you the flexibility to have control over how to convey your brand message to the target audience. At Brands N Behind, you can enjoy dedicated services at affordable advertising rates and costs.
                                </p>

                          
                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;