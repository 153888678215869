import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Bus Advertising</h3>

                                <p>The bus advertising in madurai is commonly done in the MTC buses of madurai. The ads are stuck or placed on each side of the bus so that the details of the product can be shown. We at brands n behind provide bus advertising services in madurai at the most genuine rates. We have various packages available depending on the need of our clients. Our package begins from 30 days to 90 days. As these public transport buses all through the cities so the advertisements can be visualized by a large lot of people.
                                </p>
                                <Row>
                                    <Col md={6}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/busad.jpg")} />
                                    </Col>
                                 
                                </Row>
                                <p>Thus, brands n behind is a stop shop for resolving all the auto advertising services company in madurai. We provide the below-mentioned services:
                                </p>

                                <ul>
                                    <li>Enhance interaction with your customer </li>
                                    <li>Get a blanket of coverage in your primary area </li>
                                    <li>Get visually unavoidable brand marketing </li>
                                    <li>Get a mobile billboard advertising</li>
                                    <li>Make your brand’s message visible effectively</li>
                                </ul>

                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;