import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './ad-sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                       
                        <Col md={8}>
                            <div>
                                <h3>Point of Sale Advertisement</h3>

                                <p>Point of sale marketing (or POS advertising) is, specifically, a form of in-store promotion or
                                    marketing campaign that takes place at the point a transaction occurs, in order to increase the
                                    number of purchases.
                                </p>
                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/pointofsale.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/pointofsale1.png")} />
                                            </Col>
                                    </Row>
                                <p>POS displays can include free standing display units (FSDU), shelf edging, dummy packs, strut
                                    cards, standees, hanging signs, counter display units (CDU), display packs, endcaps, display
                                    stands, mobiles, posters, and banners.</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;