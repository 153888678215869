import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';

import AOS from "aos";
import "aos/dist/aos.css";

import { NavLink } from "react-router-dom";

import { BsDot } from 'react-icons/bs';

import { MdKeyboardArrowRight } from 'react-icons/md';

function Sidebar() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='sidebars'>

                <div className="profile__body">
                    <div className="list-group p-3">
                    <h5>Ad Agency Services</h5>
                        <NavLink to="/newspaper-advertisement" className="list-group-item list-group-item-action" activeClassName="active"> <MdKeyboardArrowRight /> <span> Newspaper Advertisement</span></NavLink>
                        <NavLink to="/fmradio-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span>  FM Radio Advertisement </span> </NavLink>
                        <NavLink to="/tv-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span>  Tv Advertisement </span> </NavLink>
                        <NavLink to="/autobranding-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span>  Auto Branding Advertisement </span> </NavLink>
                        <NavLink to="/noparking-endrosement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> No Parking Boards </span> </NavLink>
                        <NavLink to="/buspackpanel-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Bus Back Panel Advertising </span> </NavLink>
                        <NavLink to="/mobilevan-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span>Mobile Van Advertising </span> </NavLink>
                        <NavLink to="/shoppingmalls-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Shopping malls Advertising</span> </NavLink>
                        <NavLink to="/offsetprinting-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Offset printing</span> </NavLink>
                        <NavLink to="/airport-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span>  Airport  Advertising </span> </NavLink>
                        <NavLink to="/busshelter-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Bus Shelter Advertising</span> </NavLink>
                        <NavLink to="/metrotrain-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Train Advertising</span> </NavLink>
                        <NavLink to="/digitalmarketing-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Digital Marketing</span> </NavLink>
                        <NavLink to="/facebook-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Social media marketing</span> </NavLink>

                        <NavLink to="/newspaperinsert-advertisement" className="list-group-item list-group-item-action " activeClassName="active"> <MdKeyboardArrowRight /> <span> Newspaper paper inserts</span> </NavLink>
                    </div>
                </div>

            </div>


        </>
    )
}

export default Sidebar;