import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                      
                        <Col md={8}>
                            <div>
                                <h3>Airport Advertising</h3>

                                <p>While, advertisers can also avail interesting advertising options outside the airports and that includes overhead signs, billboards and so on. Airport advertising in madurai is one of the attractive advertising mediums for startup advertisers and also for big business owners to reach out to the target audiences in a cost-effective manner. Advertising has a massive scope in the aviation field. Definitely, airports are the perfect locality where consumers can be persuaded effortlessly to own the stuff in the market. Advertisements published at the airport have the potential of developing a positive impact on the minds of the target audience and may turn those consumers into potential buyers.
                                </p>
                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/airport1.png")} />
                                        </Col>
                                        {/* <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/airport.png")} />
                                     
                                            </Col> */}
                                    </Row>
                                <p>madurai, most populous city in India and it has been known as the locality with the highest quality of life in entire India. madurai has people from all over the country and hence we can promote our brand to a maximum extent via airport advertising in madurai. Based on the report, it’s found that the madurai airport is the place with the largest traffic and hence we can develop a huge customer base via airport advertising. Approximately, it is estimated that around 12.9 million people are being subjected to advertisements published in the airport.    Airport advertising in madurai is one of the most demanding options of advertising preferred by business owners across the city to promote their brand. For advertising via airports in madurai, hiring a popular airport advertising agency in madurai will make your task even easier. Brands N Behind, perfect airport advertising agency in madurai and it has huge expertise in promoting your business or brand via madurai airport advertisements.
                                </p>

                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;