import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Field Study / Market Survey</h3>
                                <p>A market research survey is a way of gaining information, insights and attributes about your
                                    target consumers, so you can better understand them and what makes them tick. More
                                    information, means more knowledge, means more understanding – helping drive more
                                    successful campaigns.</p>
                                    <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/marstudy.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/marstudy1.png")} />
                                            </Col>
                                    </Row>
                                <p>Field research is defined as a qualitative method of data collection that aims to observe, interact
                                    and understand people while they are in a natural environment. For example, nature
                                    conservationists observe behavior of animals in their natural surroundings and the way they
                                    react to certain scenarios.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;