import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Market Penetration</h3>
                                <p>Market penetration is a measure of how much a product or service is being used by target
                                    customers compared to the total estimated market for that product or service. Market
                                    penetration also relates to the number of potential customers that have purchased a specific
                                    company's product instead of a competitor's product.</p>
                                    <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/Market-Penetration.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/market-penetration1.png")} />
                                            </Col>
                                    </Row>
                                <p>Penetration pricing is a marketing strategy used by businesses to attract customers to a new
                                    product or service by offering a lower price during its initial offering. The lower price helps a new
                                    product or service penetrate the market and attract customers away from competitors.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;