import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './ad-sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                      
                        <Col md={8}>
                            <div>
                                <h3>Product Placement Advertisement</h3>

                                <p>Product placement is a form of advertising in which branded goods and services are featured
                                    in a production that targets a large audience.
                                </p>
                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/productplacement.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/productplacement1.png")} />
                                            </Col>
                                    </Row>
                                <p>
                                    Product placement is a marketing technique in which a product or service is showcased in some
                                    form of media, such as television shows, movies, music videos, social media platforms, or even
                                    ads for other products. Advertising professionals sometimes call this an embedded marketing
                                    strategy.
                                </p>

                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;