import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Staff Training</h3>
                                <p>We understand that investing in your staff's development is critical to the success of your
                                    business. That's why we work closely with you to ensure that our training programs align with
                                    your overall business strategy. Our goal is to help you build a highly skilled and motivated team
                                    that can drive your business forward.</p>
                                    <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/stafftraning.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/stafftraning1.png")} />
                                            </Col>
                                    </Row>
                                <p>Our team of experienced consultants and trainers will work closely with you to understand your
                                    business needs and develop customized training programs that meet your specific
                                    requirements. Whether you need training on sales techniques, customer service, leadership, or
                                    any other aspect of your business, we have the expertise to help.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;