import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Offset Printers </h3>

                                <p>We also look after the pre-press as well as post-press infrastructure. Our team at brands n behind works with the most updated tools, we all in a way motivate each other to work with more grace and dedication. This hard work has helped us in becoming the best offset printers company not only in Madurai but in South India too. So, if you have any requirement for printing then we can do it efficiently, swiftly, and in less time. 
                                </p>
                             
                                <Row>
                                        <Col md={12}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/printer.jpg")} />
                                        </Col>
                                      
                                    </Row>
                                <p>In return, we can assure you of ace quality and timely delivery of services. Our team of designers works effortlessly in order to create the best of services to match up to the client’s requirements. Our work distinguishes us from others, the designs are out-of-the-box and unique. As a matter of fact, the offset printing these days is not just restricted to paper and ink but it has flown out of the boundaries. At brands n behind, we provide printing and delivery services to our clients. whether you need business cards, bill books, or envelopes for your company we deal with everything. 
                                </p>

                              
                                
                                <p>We also make CD and DVD stickers even it is for your client or for some family functions. We execute print enhancements for instance foil stamping or embossing. Brands n behind are known for harnessing the latest trends. We also manufacture the self-adhesive labels in the form of rolls with the help of our recent technology-based flexo-machines. These techniques help us executive to perform better and cause less wastage of materials. Our products have the highest quality finish. Our dedication and product quality have helped us in becoming one of the paramount in offset printers in Madurai. 
                                </p>

                                 
                                <p>The offset printing services include the technique wherein the inked images are firstly transferred to the rubber surface and then it is transferred to the desired printing surfaces. At times when this offset printing technique is used along with the combination of lithographic processes then it either contains the picture of the carrier or the image designed by our designers. This helps the non-printing areas clean on the surface where the image is to be printed. 
                                </p>
                              

                              


                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;