import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Digital Marketing</h3>

                                <p>Brands N Behind provide a wide range of digital marketing in madurai. We work in association with the best of entrepreneurial businesses. As we understand the need for maintaining an impressive online presence in this technical savvy era. As the competition is so fierce in today’s world so we provide the best of digital marketing services in madurai. As many of the companies undoubtedly know how to acquire clients and convert them into potential leads. At the same time, they don’t have proper management and skills to engage their audiences efficiently. All these services together help in boosting any business.
                                </p>
                                <Row>
                                    <Col md={6}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/business/digitalmar.png")} />
                                    </Col>
                                 
                                </Row>
                                <p style={{ fontWeight:"600"}}>However, there are three major pillars on which digital marketing stands still:
                                </p>
                                <ul>
                                    <li style={{ fontWeight:"600"}}>Opportunity </li>
                                    <p>As they say that in the middle of difficulty lies the opportunity. You must be keen enough to grab the opportunity as soon as it comes to you. So a good digital marketing company in madurai will help you to find positive opportunities.</p>
                                    <li style={{ fontWeight:"600"}}>Strategy  </li>
                                    <p>The next approach for digital marketing is strategy. A proper plan and management are of the utmost importance in catering to your brands to wider populations.</p>
                                    <li style={{ fontWeight:"600"}}>Action  </li>
                                 <p>The third rule lies in acting upon your plans. To get success go as your plan and then nothing will stop you from getting the best in business.</p>
                                </ul>
                          
                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;