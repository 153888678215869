import React from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./adagencies.css";
import { MdLocationOn } from 'react-icons/md';
import { FiPhoneCall } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { BiRightArrowAlt } from 'react-icons/bi';
import Iframe from 'react-iframe'

import { NavLink } from 'react-router-dom';



function Adagencies() {


    return (

        <div className='adagencies'>
            <div className='adagenciesbanner'>
                <Container>
                    <h1 className='adagencieshead'>
                        ADVERTISEMENT
                    </h1>
                </Container>
            </div>

            <div className='adss'>
                <Container>
                    <h3> AD AGENCIES IN MADURAI</h3>
                    <Row>
                    <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6'>
                                    <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/newspaper.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                    <div className='paddtl'>
                                        <h1 className='cardh1'>
                                            Newspaper Advertisement
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Newspaper Advertising
                                        </p>
                                        
                                            <a href='/newspaper-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More </span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/fm.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                            FM Radio Advertisement
                                        </h1>
                                        <p className='cardp'>
                                        View Ad Rates and packages of FM radio
                                        </p>
                                        
                                            <a href='/fmradio-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/tv.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                            Tv Advertisement
                                        </h1>
                                        <p className='cardp'>
                                        View Ad Rates and packages of  Tv Advertising
                                        </p>
                                        
                                            <a href='/tv-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/auto.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                            Auto Branding Advertisement
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Auto Branding
                                        </p>
                                        
                                            <a href='/autobranding-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More </span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/noparking.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                            No Parking Boards
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of No Parking Boards
                                        </p>
                                        
                                            <a href='/noparking-endrosement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/busback.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                          Bus Back Panel Advertising
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Bus Back Panel Advertising
                                        </p>
                                        
                                            <a href='/buspackpanel-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/mobilevan.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                           Mobile Van Advertising
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Mobile Van Advertising
                                        </p>
                                        
                                            <a href='/mobilevan-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More </span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/shopping.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                         Shopping malls Advertising
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Shopping malls Advertising
                                        </p>
                                        
                                            <a href='/shoppingmalls-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/offset.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                          Offset printing
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Offset printing
                                        </p>
                                        
                                            <a href='/offsetprinting-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/airport.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                           Airport  Advertising
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Airport  Advertising
                                        </p>
                                        
                                            <a href='/airport-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More </span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/busshelter.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                          Bus Shelter Advertising
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Bus Shelter Advertising
                                        </p>
                                        
                                            <a href='/busshelter-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/metro.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                          Train Advertising
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Metro Train 
                                        </p>
                                        
                                            <a href='/metrotrain-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/mic.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                        Digital Marketing
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Digital Marketing
                                        </p>
                                        
                                            <a href='/digitalmarketing-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More </span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/social.jpg")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                         Social media marketing
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Social media marketing
                                        </p>
                                        
                                            <a href='/facebook-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className='cards'>
                              <div className='row' style={{ alignItems:"center" }}>
                                   <div className='col-md-6 col-6 '>
                                   <div className="img-hover-zoom">
                                    <img style={{ width:'100%' }} src={require("../../assets/newsadd.png")} />
                                    </div>
                                    </div>
                                   <div className='col-md-6 col-6'>
                                   <div className='paddtl'>
                                        <h1 className='cardh1'>
                                          Newspaper paper inserts
                                        </h1>
                                        <p className='cardp'>
                                            View AD Rates and Packages of Newspaper paper inserts
                                        </p>
                                        
                                            <a href='/newspaperinsert-advertisement' className='homeatag'><button type="button" class="btn btn-primary "><span>Read More</span> <BiRightArrowAlt className='marbtm4' /></button></a>
                                        
                                    </div>
                                    </div>
                                        </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>

        </div>


    )
}
export default Adagencies;