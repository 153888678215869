import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Business Consultancy Services</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                        <Col md={8}>
                            <div>
                                <h3>Branding</h3>
                                <p>Branding is the process of creating a distinct identity for a business in the mind of your target
                                    audience and consumers. At the the most basic level, branding is made up of a company's logo,
                                    visual design, mission, and tone of voic</p>
                                    <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/brandfree.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/business/brandfree1.png")} />
                                            </Col>
                                    </Row>
                                <p>Think about your company's unique value proposition. And begin to try and articulate that.
                                    Conduct customer research if you feel that it could help you better understand what your
                                    customers want and need. Think about how you can position your company with a clear, strong
                                    image that will resonate with customers.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;