import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>No Parking Board Advertising</h3>

                                <p>Nowadays, no parking board advertising in Madurai seems to be the wide choice of advertisers since they can earn all their expectations just by spending affordable ad rates. The attractive features of no parking advertisements include ease of installation, longevity, and huge acceptability among the target consumers. For advertising, via no parking boards in Madurai, the advertisers need not seek any permission from the homeowners or the residents in most scenarios and hence it’s regarded as one of the fast mushrooming options in the city.
                                </p>
                             
                                <Row>
                                       
                                        <Col md={12}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/nopa1.jpg")} />
                                        </Col>
                                    </Row>
                                <p>No parking boards are actually signboards installed in front of the bungalow gates, colony or society gates, and poles and so on. Almost all no parking boards are installed with the aim of reserving the parking slot area for the house owner’s vehicle while they are returning home. Advertising your business or brand via no parking board advertising in Madurai is not only viewed by the house owners or the residents but also by the passerby’s and by the people standing near the location where the advertisement has been placed. No parking board ads may indulge curiosity and urge in the minds of the viewers and hence your brand may stand on their minds both in the subconscious as well as the conscious level.
                                </p>

                              
                                
                                <p>No parking board advertising in Madurai is the affordable advertising option and it comes under the category of outdoor advertisement. The target consumers can’t skip from viewing the advertisement since it is visually unavoidable, unlike other traditional and digital advertising options. No parking board advertising rates and costs are fixed based on various factors like board material, dimension or size of the board, printing color, and the advertising content as well as design. Present days, no parking board ads are the commonly used form of advertising medium by all businesses in Madurai. In Madurai prime locations like Vadapalani, West Mambalam, Kodambakkam, Adyar, Velachery, Besant Nagar, and so on, you can find no parking advertisement boards in front of the commercial as well as residential buildings.
                                </p>

                                 
                                <p>No parking board advertising agency in Madurai is responsible for creating innovative and catchy advertising content with creative designs for reaching the target consumers in the market. Brands N Behind, a popular no parking board advertising agency in Madurai will cater to all your needs without any backlogs. You can advertise your business via Brands N Behind and definitely we ensure with attractive services and achievements in the market.
                                </p>
                              

                              


                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;