import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button, Form } from 'react-bootstrap';
import "./contactus.css";
import { MdLocationOn } from 'react-icons/md';
import { FiPhoneCall } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { BiRightArrowAlt } from 'react-icons/bi';
import Iframe from 'react-iframe'

import { NavLink } from 'react-router-dom';

import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

import AOS from 'aos';
import 'aos/dist/aos.css';

import emailjs from '@emailjs/browser';

function Contactus() {


    const initialValues = { username: "", email: "", phonenumber: "", message: "", subject: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e, type) => {


        console.log("dsfsdf", e.target.value)

        if (type == "username") {
            var data = { ...formValues }
            data.username = e.target.value
            setFormValues(data)
        }
        else if (type == "email") {
            var data = { ...formValues }
            data.email = e.target.value
            setFormValues(data)
        }
        else if (type == "phonenumber") {
            var data = { ...formValues }
            data.phonenumber = e.target.value
            setFormValues(data)
        }
        else if (type == "message") {
            var data = { ...formValues }
            data.message = e.target.value
            setFormValues(data)
        }
        else if (type == "subject") {
            var data = { ...formValues }
            data.subject = e.target.value
            setFormValues(data)
        }
    };

    const handleSubmit = async (e) => {
        // window.open('mailto:info@fimagscorporate.com?subject='+"sasisiiiiii"+ "&body="+"thasssssssssss");

        const res = await validate(formValues)


        console.log('ressssssss', res);

        // e.preventDefault();

        if (res == {}) {
            alert("sucesss")

        }
        else {

            setFormErrors(validate(formValues));
            console.log(formErrors, "FUCK")
            if (Object.keys(res).length === 0) {
                swal({
                    title: "Send the Request Successfully.",
                    icon: "success",

                }).then(function () {
                    window.location.reload()
                });


            } else {
                if (formValues.subject && formValues.phonenumber && formValues.username && formValues.message && formValues.email) {
                    const templateParams = {
                        username: formValues.username,
                        subject: formValues.subject,
                        email: formValues.email,
                        phonenum: formValues.phonenumber,
                        message: formValues.message
                    };
                    emailjs.send('service_da5n25nbr', 'template_8agoa4a', templateParams, 'f8eZM-pYGlWPBsh7Q')
                        .then((response) => {
                            console.log('SUCCESS!', response.status, response.text);
                        }, (err) => {
                            console.log('FAILED...', err);
                        });

                }
            }


        };
    }

    const validate = (values) => {

        console.log(values, "bbbbbbbbbbbb")

        const errors = {};


        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const re = /^[6-9]{1}[0-9]{9}$/;
        // const regex1 =  new RegExp(/"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/gmi);

        if (!values.username) {
            errors.username = "Name is required!";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!values.phonenumber) {
            errors.phonenumber = "Phonenumber is required!";
        } else if (!re.test(values.phonenumber)) {
            errors.phonenumber = "This is not a valid Mobile Number!";
        }

        if (!values.message) {
            errors.message = "Message is required!";
        }
        if (!values.subject) {
            errors.subject = "Subject is required!";
        }
        if (!values.username && !values.message && !values.message) {

        }



        return errors;


    };





    // useEffect(() => {
    //     console.log(formErrors);
    //     if (Object.keys(formErrors).length === 0 && isSubmit) {
    //         console.log(formValues);
    //     }
    // }, [formErrors]);






    const key = "6LfghXglAAAAAAxomhRSiYOy0C4Loay8_MK3Ufct";
    const [captchavalue, setcaptchavalue] = useState(false);

    function onChangerecaptcha(value) {
        console.log("Captcha value");
        setcaptchavalue(true);
    }


    useEffect(() => {
        AOS.init({
            duration: 2000
        });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);


    return (

        <div className='contactus'>
            <div className='contactbanner'>
                <Container>
                    <h1 className='contacthead'>
                        Contact Us
                    </h1>



                </Container>
            </div>


            <div className='adrsspadd'>
                <Container>
                    {/* <div>
                        <h1 className='contact2ndhead'>
                            Our Address
                        </h1>
                        <p className='contactpara'>
                            Welcome to Ignite Branding Consultancy Services
                        </p>
                    </div> */}

                    <Row>
                        <Col md={4}>
                            {/* <div className='dflex'>
                                <div>
                                    <MdLocationOn className='fonticon' />
                                </div>
                                <div className='padlft'>
                                    <h5 className='conth5'>
                                        Location
                                    </h5>
                                    <p className='contpara' >
                                        115,Coronation Street,Arasadi,<br />
                                        Near Solamalai cinemas,<br />
                                        Madurai-625 016
                                    </p>
                                </div>
                            </div> */}

                            <div className='card' style={{ marginTop: "10px" }}>
                                <div class="c-subscribe-box u-align-center">
                                    <div class="rainbow"><span></span><span></span></div>
                                    <div class="c-subscribe-box__wrapper">
                                        <div className='dflex'>
                                            <div>
                                                <MdLocationOn className='fonticon' />
                                            </div>
                                            <div className='padlft'>
                                                <h5 className='conth5'>
                                                    Location
                                                </h5>
                                                <p className='contpara' >
                                                    115,Coronation Street,Arasadi,<br />
                                                    Near Solamalai cinemas,<br />
                                                    Madurai-625 016
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </Col>
                        <Col md={4}>
                            {/* <div className='dflex'>
                                <div>
                                    <FiPhoneCall className='fonticon' />
                                </div>
                                <div className='padlft'>
                                    <h5 className='conth5'>

                                        Support
                                    </h5>
                                    <p className='contpara'>
                                        Mobile: +91 96551 26222
                                    </p>
                                </div>
                            </div> */}

                            <div className='card' style={{ marginTop: "10px" }}>
                                <div class="c-subscribe-box u-align-center">
                                    <div class="rainbow"><span></span><span></span></div>
                                    <div class="c-subscribe-box__wrapper">
                                        <div className='dflex'>
                                            <div>
                                                <FiPhoneCall className='fonticon' />
                                            </div>
                                            <div className='padlft'>
                                                <h5 className='conth5'>

                                                    Support
                                                </h5>
                                                <p className='contpara'>
                                                    Mobile : +91 96551 26222
                                                </p>
                                                <p className='contpara'>
                                                    Landline : 0452 - 495 00 11
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={4}>
                            {/* <div className='dflex'>
                                <div>
                                    < FaRegClock className='fonticon' />
                                </div>
                                <div className='padlft'>
                                    <h5 className='conth5'>
                                        Get In Touch
                                    </h5>
                                    <p className='contpara'>
                                        Monday - Saturday: 10:00 AM to 08:00 PM <br /> Closed on  Sunday.
                                    </p>
                                </div>
                            </div> */}

                            <div className='card' style={{ marginTop: "10px" }}>
                                <div class="c-subscribe-box u-align-center">
                                    <div class="rainbow"><span></span><span></span></div>
                                    <div class="c-subscribe-box__wrapper">
                                        <div className='dflex'>
                                            <div>
                                                < FaRegClock className='fonticon' />
                                            </div>
                                            <div className='padlft'>
                                                <h5 className='conth5'>
                                                    Get In Touch
                                                </h5>
                                                <p className='contpara'>
                                                    Monday - Saturday: 10:00 AM to 08:00 PM <br /> Closed on  Sunday.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>

                </Container>
            </div>






            <div className='cotback'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div>
                                <h1 className='readytext'>
                                    Ready to Get Started?
                                </h1>
                                <p className='welcmpara'>
                                    Welcome to Ignite branding Consultancy Services
                                </p>
                            </div>
                            <div>

                                <Form>
                                    <Row className='mtop30'>

                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="text" placeholder="Your name *" value={formValues.username}
                                                    onChange={(e) => handleChange(e, "username")} />
                                                <p className='errormsg'>{formErrors.username}</p>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="text" placeholder="Your Phonenumber *" value={formValues.phonenumber}
                                                    onChange={(e) => handleChange(e, "phonenumber")} />
                                                <p className='errormsg'>{formErrors.phonenumber}</p>
                                            </Form.Group>
                                        </Col>




                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="email" placeholder="Your email address *" value={formValues.email}
                                                    onChange={(e) => handleChange(e, "email")} />
                                                <p className='errormsg'>{formErrors.email}</p>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="text" placeholder="Your subject *" value={formValues.subject}
                                                    onChange={(e) => handleChange(e, "subject")} />
                                                <p className='errormsg'>{formErrors.subject}</p>
                                            </Form.Group>
                                        </Col>


                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Control as="textarea" rows={3} placeholder="Your message *" value={formValues.message}
                                                    onChange={(e) => handleChange(e, "message")} />
                                                <p className='errormsg'>{formErrors.message}</p>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <ReCAPTCHA
                                        sitekey={key}
                                        onChange={onChangerecaptcha}
                                    />

                                    <div>
                                        <button type="button" onClick={handleSubmit} class="btn btn-primary "><span>Send Message</span> <BiRightArrowAlt className='marbtm4' /></button>
                                    </div>

                                </Form>

                            </div>


                        </Col>



                        <Col md={6}>

                            <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31440.751952512623!2d78.06492767910154!3d9.926129699999988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00cf48aa341e25%3A0xf1fa60feb9b22b09!2sIgnite%20Branding%20Consultancy!5e0!3m2!1sen!2sin!4v1680846553258!5m2!1sen!2sin" width="700" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>


    )
}
export default Contactus;