import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './adagencysidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>

                        <Col md={8}>
                            <div>
                                <h3>Shopping Mall Advertising </h3>

                                <p>To think about it, people visiting the shopping malls are already there to buy things. This especially goes out to the youth who are very particular about what they wear and what they eat. The primary idea for them is to be trendy. This attribute of the youth and the buyer persona, in general, can come into use if a brand is planning a new product launch. By taking up shopping mall advertisements in Madurai, a brand gets the chance to establish a closer link to the clientele.
                                </p>
                             
                                <Row>
                                        <Col md={12}>
                                        <img style={{ marginTop: "10px", width:"100%" }} className='abtimgwidth' src={require("../../assets/vishal de mall.jpg")} />
                                        </Col>
                                      
                                    </Row>
                                <p>Shopping mall advertisement in Madurai gives the brand a chance to focus on localized branding, where Standees and Banners can be used to grab the attention of the customers. For the benefits that it offers, shopping mall advertisement in Madurai can help in developing brand awareness, establishing new brands, and remind customers about the existing brands. So, if you are considering shopping mall advertisement in Madurai, it can indeed be lucrative for you.
                                </p>

                              
                              
                              

                              


                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;