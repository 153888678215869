import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Navbar, NavDropdown, Nav, Card, Button } from 'react-bootstrap';
// import "./services.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from './ad-sidebar';


function Services() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>

            <div className='business-consul'>
                <Container>
                    <h2>Advertising and Promotions</h2>
                </Container>
            </div>

            <div className='services1'>
                <Container>
                    <Row>
                     
                        <Col md={8}>
                            <div>
                                <h3>Celebrity Endorsement</h3>

                                <p>Celebrity branding is a form of advertising campaign or marketing strategy which uses a
                                    celebrity's fame or social status to promote a product, brand or service, or to raise awareness
                                    about an issue.
                                </p>
                                <Row>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/celebrity.png")} />
                                        </Col>
                                        <Col md={6}>
                                        <img style={{ marginTop: "10px" }} className='abtimgwidth' src={require("../../assets/ad/celebrity1.png")} />
                                            </Col>
                                    </Row>
                                <ul>
                                    <li>Build brand equity. Prior to Michael Jordan, Nike primarily sponsored tennis and track athletes</li>
                                        <li>Help people remember ads. </li>
                                        <li>Make people believe the product contributes to superstar status. </li>
                                        <li>Stand out</li>                                       
                                </ul>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Sidebar />
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default Services;